const element = "./#/comingsoon";

export const year_1430 = [
  {
    month: "ذو الحجة",
    year: "2009",
    archive: element,
    drive:
      "https://drive.google.com/drive/folders/1P5D8a7p6BXAkSMPRreDpDB2USCoyeQbM",
  },
  {
    month: "شهر محرم",
    year: "2009",
    archive: element,
    drive:
      "https://drive.google.com/drive/folders/1ZdWAKr2bkO7S76jaVfZvdafWoXEllFg7",
  },
];
