const element = "./#/comingsoon";

export const year_1433 = [
  {
    month: "ذو الحجة",
    year: "2012",
    archive: element,
    drive:
      "https://drive.google.com/drive/folders/1cd8Rc4upJtFgdI4412GXTwLfeyYcfhr9",
  },
  {
    month: "شهر رمضان",
    year: "2012",
    archive: element,
    drive:
      "https://drive.google.com/drive/folders/1ct2dT1UW5xi6bJ2jLCbUjtTIcYn2c1lJ",
  },
];
