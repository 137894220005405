const element = "./#/comingsoon";

export const year_1431 = [
  {
    month: "شهر رمضان",
    year: "2010",
    archive: element,
    drive:
      "https://drive.google.com/drive/folders/14eH0wL3SwiGIx4qRltKZ4AxCyakumgsz",
  },
];
