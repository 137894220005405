const element = "./#/comingsoon";
export const year_1427 = [
  {
    month: "ذو الحجة",
    year: "2006",
    archive: element,
    drive:
      "https://drive.google.com/drive/folders/1jZKuCB0ji0YbwXdb1dvnk2GlkskY_uPl",
  },
  {
    month: "شهر شعبان",
    year: "2006",
    archive: element,
    drive:
      "https://drive.google.com/drive/folders/1G-qgaMDWmG3YdY_97ECMO6esEjdT3J1M",
  },
  {
    month: "شهر محرم",
    year: "2006",
    archive: element,
    drive:
      "https://drive.google.com/drive/folders/1HkF2JhuAOz2lS05a7vxpXteNZkdMPx_d",
  },
  {
    month: "شهر رمضان",
    year: "2005",
    archive: element,
    drive:
      "https://drive.google.com/drive/folders/1v6JoRbxMRSyDTa3EdWLUPdZJu81OQry5",
  },
];
