import React, { Component } from 'react';
import Navbar from './Navbar';
import Footer from './footer';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Eid2023 from '../img/eidadha2023.jpg';
import Eid2021 from '../img/moon.jpg';

export default class Ramadan2021 extends Component {
  render() {
    return (
      <div>
        <Navbar />
        {/*Title */}
        <div className="application">
          <Helmet>
            <meta charSet="utf-8" data-react-helmet="true" />
            <title>فضيلة الشيخ ظفر الحسن مدني حفظه الله</title>
            <link
              rel="canonical"
              href="https://zafarulhasan.com/#/audio/Ramadan-1442-2021"
            />
            <meta
              name="title"
              content="Zafar ul hasan madani | Ramadan Duroos"
            />
            <meta
              name="description"
              content="Ramadan 1442-2021 - Listen and Download Ramadan duroos by zafar ul hasan madani"
            />
            <meta
              name="keywords"
              content="ramadan, zafar ul hasan madani, ramadan 2021, ramadan 1442"
            />
          </Helmet>
        </div>
        {/*Banner section*/}
        <div className="container-fluid">
          <div className="row justify-center">
            <img
              src={Eid2023}
              className="img-fld"
              alt="zafar ul hasan madani"
              width="100%"
            />
          </div>
        </div>
        {/*Title */}
        <section className="embed-section">
          <div className="container">
            <div className="row text-center">
              <div class="col-md-12">
                <h5 className="audio-display-head"> ذو الحجة</h5>
                <p
                  className="lead"
                  style={{ fontFamily: 'Fredoka One', fontSize: '20px' }}
                >
                  DhulHijjah 1444-2023
                </p>
                <hr />
              </div>
            </div>
          </div>
        </section>
        {/*Rounded Eid button */}
        {/*Rounded button links 1*/}
        <section className="embed-section">
          <div className="container-fluid py-3">
            <div className="row text-center ">
              <div className="col-md-12 d-flex justify-content-center">
                <a
                  href="https://youtu.be/zx0ohzkZfpk"
                  target="_blank"
                  rel="noreferrer noopener"
                  title="manhaj"
                >
                  <div className="rounded-links rounded-circle">
                    <img
                      src={Eid2021}
                      className="img-fluid rounded-circle"
                      alt="ahle hadith"
                    />
                  </div>
                  <div>
                    <h3 className="rounded-headname d-flex justify-content-center">
                      Khutbah Eid-al-Fitr <br />
                      (1442/2021)
                    </h3>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </section>
        <section className="embed-section">
          <div className="col-md-12 text-center">
            <Footer />
          </div>
        </section>
      </div>
    );
  }
}
