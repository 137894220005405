const element = "./#/comingsoon";

export const year_1440 = [
  {
    month: "ذو الحجة",
    year: "2019",
    archive: element,
    drive:
      "https://drive.google.com/drive/folders/1nPLJ3HqOEfO5Z2brUTM8J08BFOIhF2B5",
  },

  {
    month: "شهر محرم",
    year: "2019",
    archive: element,
    drive:
      "https://drive.google.com/drive/folders/17rIjoEMuYkIszZatYL9_3bNoGrCkyOjD",
  },
  {
    month: "شهر رمضان",
    year: "2019",
    archive: element,
    drive:
      "https://drive.google.com/drive/folders/17rIjoEMuYkIszZatYL9_3bNoGrCkyOjD",
  },
];
