const element = "./#/comingsoon";

export const year_1439 = [
  {
    month: "ذو الحجة",
    year: "2018",
    archive: element,
    drive:
      "https://drive.google.com/drive/folders/1UGrqrLjI_WpfPHum75EpT3Rc_TO77o3a",
  },

  {
    month: "شهر محرم",
    year: "2018",
    archive: element,
    drive:
      "https://drive.google.com/drive/folders/1eWpXO9frAcb3jLrUWEse3Ry-N09os4_w",
  },
  {
    month: "شهر رمضان",
    year: "2018",
    archive: element,
    drive:
      "https://drive.google.com/drive/folders/1_tP1EQfVOjfUvGRcCp_5YkVDAxntAakk",
  },
];
