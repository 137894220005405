const element = "./#/comingsoon";

export const year_1428 = [
  {
    month: "ذو الحجة",
    year: "2007",
    archive: element,
    drive:
      "https://drive.google.com/drive/folders/1SlixjKlmWs2ApkcCxQwcx6uyfpHv65wc",
  },
  {
    month: "شهر محرم",
    year: "2007",
    archive: element,
    drive:
      "https://drive.google.com/drive/folders/1hCxCMYGCYYoyqU-lp5sBVLY4uVAbn7D7",
  },
];
