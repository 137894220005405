const element = "./#/comingsoon";

export const year_1429 = [
  {
    month: "ذو الحجة",
    year: "2008",
    archive: element,
    drive:
      "https://drive.google.com/drive/folders/1dcStXVwISR4FsdnJLibr2lYnNRwc6NI6",
  },
  {
    month: "شهر رمضان",
    year: "2008",
    archive: element,
    drive:
      "https://drive.google.com/drive/folders/14N0Np7wlRRWu9ek2ZNhNLyjzKS9zHFso",
  },
];
