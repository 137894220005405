const element = "./#/comingsoon";

export const year_1441 = [
  {
    month: "شهر محرم",
    year: "2020",
    archive: element,
    drive:
      "https://drive.google.com/drive/folders/1hQ36oXv8fGQR5tbDmkUdaEAwoX5IyW8X",
  },

  {
    month: "شهر رمضان",
    year: "2020",
    archive: element,
    drive:
      "https://drive.google.com/drive/folders/1XiuTdAZQhuAQbMy0VwMJjo9MwDG7LIyx",
  },
];
