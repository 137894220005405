const element = "./#/comingsoon";

export const year_1438 = [
  {
    month: "ذو الحجة",
    year: "2017",
    archive: element,
    drive:
      "https://drive.google.com/drive/folders/1oWe9uNHBxkSsc2PLb24iBHnNpasOfFhf",
  },

  {
    month: "شهر محرم",
    year: "2017",
    archive: element,
    drive:
      "https://drive.google.com/drive/folders/1ETBGFVavERAanIPy3YxKZcBfBbv04ZQ4",
  },
  {
    month: "شهر رمضان",
    year: "2017",
    archive: element,
    drive:
      "https://drive.google.com/drive/folders/1GDQkNTKtdTulk6NziFoTpfuukJlm545L",
  },
];
