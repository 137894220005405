const element = "./#/comingsoon";

export const year_1436 = [
  {
    month: "ذو الحجة",
    year: "2015",
    archive: element,
    drive:
      "https://drive.google.com/drive/folders/1CmasEOKoBaz5qhz4LqF5D0Qg1ZsaVSZV",
  },
  {
    month: "شهر شعبان",
    year: "2015",
    archive: element,
    drive:
      "https://drive.google.com/drive/folders/1lNQTBpYAMx4ZC9P277HM02hmvVKbGPab",
  },
  {
    month: "شهر رمضان",
    year: "2015",
    archive: element,
    drive:
      "https://drive.google.com/drive/folders/1F3aqyaxxeJ39uEmw5qUjW-53ZLLMvtlf",
  },
  {
    month: "شهر محرم",
    year: "2015",
    archive: element,
    drive:
      "https://drive.google.com/drive/folders/11lnDGBkJ0FDIxMvzi_r0FbeJnW9Rq-xB",
  },
  {
    month: "شهر شعبان",
    year: "2015",
    archive: element,
    drive:
      "https://drive.google.com/drive/folders/12Ds8NE8qqLhf4RwVZwVMvJTGjON-JEPk",
  },
];
