const element = "./#/comingsoon";

export const year_1432 = [
  {
    month: "ذو الحجة",
    year: "2011",
    archive: element,
    drive:
      "https://drive.google.com/drive/folders/1L__GPazNquGenjOzLL1T2mLIWkXvj0an",
  },
  {
    month: "شهر رمضان",
    year: "2011",
    archive: element,
    drive:
      "https://drive.google.com/drive/folders/17qxJSlaynUcsY1dSTaoDgQRN9apgY-bA",
  },
  {
    month: "شهر صفر",
    year: "2011",
    archive: element,
    drive:
      "https://drive.google.com/drive/folders/1PSjoFMhOjZew_D4ibyDOTP3P0JcGFMaT",
  },
];
