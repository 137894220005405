export const audios = [
  {
    _id: "audio1",
    iframe:
      "https://drive.google.com/file/d/1WuNbiof1DBSWsnHB4HS4bpOGw359h0mM/preview",
  },
  {
    _id: "audio2",
    iframe:
      "https://drive.google.com/file/d/1rvxZspNZ1m7HOqeUPgFOQ-0BVVmoVD3c/preview",
  },
  {
    _id: "audio3",
    iframe:
      "https://drive.google.com/file/d/1V-lGBYb2tqFPwUtaC9eAIwPN3-y59A5w/preview",
  },
  {
    _id: "audio4",
    iframe:
      "https://drive.google.com/file/d/15Uq03eCkpH9DamcdPW8Ftv-quS3sROmw/preview",
  },
  {
    _id: "audio5",
    iframe:
      "https://drive.google.com/file/d/1zD6HGhF-hLcah_NlErP49RRr4YpQNwY2/preview",
  },
  {
    _id: "audio6",
    iframe:
      "https://drive.google.com/file/d/1tSWelfCXKCHa8RrgZVH49aZNqdseuUng/preview",
  },
  {
    _id: "audio7",
    iframe:
      "https://drive.google.com/file/d/1pyCEUMw9AySLEnNg7RujVB6oi7LDMArz/preview",
  },
];
