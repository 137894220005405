const element = "./#/comingsoon";

export const year_1443 = [
  {
    month: "شهر رمضان",
    year: "2022",
    archive: element,
    drive:
      "https://drive.google.com/drive/folders/1ayp-6NoQvihLrBwH-CACsiYS8xi-6hUw?usp=sharing",
  },
];
