const element = "./#/comingsoon";

export const year_1435 = [
  {
    month: "ذو الحجة",
    year: "2014",
    archive: element,
    drive:
      "https://drive.google.com/drive/folders/1cS7BHbsLTwnCEQWZktB2BtMiuPrdxk5u",
  },
  {
    month: "شهر شعبان",
    year: "2014",
    archive: element,
    drive:
      "https://drive.google.com/drive/folders/1lNQTBpYAMx4ZC9P277HM02hmvVKbGPab",
  },
  {
    month: "شهر رمضان",
    year: "2014",
    archive: element,
    drive:
      "https://drive.google.com/drive/folders/1U_MxOLLCpbeIcV3I8GC4Q_HcdMTHMCry",
  },
];
