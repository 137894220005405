const element = "./#/comingsoon";

export const year_1437 = [
  {
    month: "ذو الحجة",
    year: "2016",
    archive: element,
    drive:
      "https://drive.google.com/drive/folders/1uV9ijHNwRvsa4FSwF3Dad51eTbUhw13p",
  },
  {
    month: "شهر رمضان",
    year: "2016",
    archive: element,
    drive:
      "https://drive.google.com/drive/folders/1LyUWxKUJ4a9mwbUIgmpvtwsJDURTAqFb",
  },
];
